import React from 'react'
import { Link } from 'gatsby'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import '../styles/case.sass'

export default class Case extends React.Component {

  render() {
    const { post, index } = this.props
    const overrideColor = post.acf.case_color ? '#000' : '#fff';
    const overrideBgColor = post.acf.case_color ? post.acf.case_color : '#000';

    return (
      <>
        <div className="case">
          <div className="case-letterWrapper">
            <Plx parallaxData={ [ParallaxConfig('backgroundPositionY', 0, -200, 2000)] } className="plx case-letter" style={{backgroundImage: 'url(' + (post.featured_media && post.featured_media.localFile ? post.featured_media.localFile.childImageSharp.fluid.src : null) + ')'}}>
              {post.acf.case_letter ? post.acf.case_letter : post.title[0]}
            </Plx>
          </div>
          <Plx parallaxData={ [ParallaxConfig('translateY', 0, -200, 2000)] } className="caseWrapper">
            <Plx parallaxData={ [ParallaxConfig('opacity', 0, 1, 200),ParallaxConfig('translateY', 100, 0, 400)] }>
              {post.acf.tagline &&
              <a href={`/case/${post.slug}/`} className="plx case-title">
                {post.acf.tagline.split(' ').map((word, index) => (
                  <span key={index} className="case-title-word" style={{backgroundColor: overrideBgColor, color: overrideColor}}>{word}</span>
                ))}
              </a>
              }
              <div className="caseWrapper-excerpt">
                <div
                  className="case-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.replace(/<p class="link-more.*/, ''),
                  }}
                />
                <div className="case-button">
                  <a className="button" href={`/case/${post.slug}/`}>
                    See case
                  </a>
                </div>
              </div>
            </Plx>
          </Plx>
        </div>
      </>
    )
  }
}
