import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Plx from 'react-plx';
import Case from '../components/Case'
import ParallaxConfig from '../components/PlxConfig'
import SectionStyles from '../styles/sections.sass'

function workingStatusTitle (post) {
  let title = 'PROUD WORKING WITH';
  post.categories.map((category) => {
    if (category.slug == 'wip') {
      title = 'NOW WORKING WITH';
      return
    }
  });

  return title;
}

export default class ListPage extends React.Component {
  render() {
    const { posts, title, showAll } = this.props
    let majorCases = []
    let minorCases = []

    posts.map(({ node: post }) => (
      showAll || post.sticky ? majorCases.push(post) : minorCases.push(post.title)
    ));
    let allCases = minorCases.concat(majorCases.map(item => item.title))
    allCases = allCases.map(item => item.replace("&#038;", "&").replace("&#8211;", "-"))
    return (
      <section className="section">
        <div className="container">
          <div className="content">
            {majorCases.map((post, index) => (
              <div className="sectionWrapper sectionWrapper--cases" key={post.id}>
                <Plx parallaxData={ [ParallaxConfig('translateX', (index % 2 == 0 ? -200 : 200), 0, 400), ParallaxConfig('translateY', 0, -200, 2000)] } className="plx sectionHeader sectionHeader--caseList">
                    {post.title.replace("&#038;", "&")}
                </Plx>
                <Case post={post} index={index} />
              </div>
            ))}
          </div>
        </div>
        {allCases.length > 0 &&
          <>
            <div className="alignLogo">We are also proud to work with</div>
            <div><div className={"minorCases marquee"}> {allCases.join(', ')}</div></div>
            <div style={{textAlign: 'center', paddingBottom: '100px'}}>
            </div>
          </>
        }
      </section>
    )
  }
}

ListPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POSTEdge {
    node {
      id
      title
      excerpt
      date(formatString: "MMMM DD, YYYY")
      slug
      sticky
      categories {
        slug
      }
      featured_media {
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
              #srcWebp
            }
          }
        }
      }
      acf {
        tagline
        text
        case_letter
        case_color
      }
    }
  }
`
