import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import PostList from '../components/PostList'
import Award from '../components/Award'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import heroStyles from '../styles/hero.sass';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { heroTextShifty: false, innerHeight: null }
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.heroText()
      })
    }, { passive: true });

    window.addEventListener('resize', () => {
      this.resizeHandling()
    }, { passive: true });

    this.heroText()
    this.resizeHandling()
    this.setState({ innerHeight: window.innerHeight })
  }

  heroText() {
    const target = document.querySelector('.heroText--focused');
    let currentOffset = target ? target.getBoundingClientRect().top : 200;

    if (currentOffset < 20 || scrollY > this.state.innerHeight / 4) {
      this.setState({ heroTextShifty: true })
    } else {
      this.setState({ heroTextShifty: false })
    }
  }

  resizeHandling() {
    this.setState({ innerHeight: window.innerHeight })
  }

  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost
    const startPage = data.wordpressPage;
    const heroFirst = data.allWordpressAcfOptions.edges[0].node.options.hero_content[0];
    const heroSecond = data.allWordpressAcfOptions.edges[0].node.options.hero_content[1];
    let users = [];
    
    // data.allSlackUser.edges.map(({ node: user }) => (
    //   users[user.userId] = {
    //     name: user.real_name,
    //     image: user.image_48
    //   }
    // ));
    //users['botuserid'].image = "";

    return (
      <>
        <Layout index={true} metaimage={heroFirst.media.localFile ? heroFirst.media.localFile.publicURL : null}/>
          <div className={"hero hero--front"}>
            {heroFirst.media.localFile ? 
            <video className="hero-video" autoPlay muted playsInline loop key="heroVideo">
              <source src={heroFirst.media.localFile.publicURL} type={heroFirst.media.mime_type}/>
            </video>
            : null}
          </div>
          <div className={(this.state.heroTextShifty ? ' heroText--shift' : '')}>
            <Plx parallaxData={ [ParallaxConfig('top', this.state.innerHeight / 2, 58, this.state.innerHeight - 200, 'px')] } className={"plx heroText heroText--focused"}>
              <div className="heroText--source">{heroFirst.text_fields.highlight_text}</div>
            </Plx>
            <Plx parallaxData={ [ParallaxConfig('top', this.state.innerHeight - 246, 58, this.state.innerHeight - 200, 'px')] } className="heroText--award">
              <Award/>
            </Plx>
            <Plx parallaxData={ [ParallaxConfig('opacity', 0, 1, 400)] } className="heroTextWrapper section">
              <div className={"heroText--target"}>{heroFirst.text_fields.primary_text}</div>
              <div className="heroText heroText--primary">
                {heroSecond.text_fields.highlight_text}
              </div>
              <div>
                <p className="heroText heroText--secondary">{heroSecond.text_fields.primary_text}</p>
                <p className="heroText heroText--last">{heroSecond.text_fields.article_text}</p>
              </div>
            </Plx>
          </div>
        <PostList posts={posts} users={users} />
        <Footer users={users} />
      </>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    allWordpressPost(
      filter: { title : { ne : "Demo" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
          ...PostListFields
      }
    }
    allWordpressAcfOptions{
      edges{
        node{
          options{
            hero_content {
              media {
                mime_type
                localFile {
                  publicURL
                }
              }
              text_fields{
                highlight_text
                primary_text
                article_text
              }
            }
          }
        }
      }
    }
  }
`
